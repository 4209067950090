import React, { useEffect, useState } from 'react';
import Header from '../layouts/Header';
import Info from '../layouts/Info';
import Testimonial from '../layouts/Testimonial';
import ParagraphBig from '../components/ParagraphBig';
import ActionBar from '../components/ActionBar';
import Footer from '../components/Footer';
import Harmonica from '../components/Harmonica';
import HighLight from '../layouts/Highlight';
import Introduction from '../layouts/Introduction';
import { client } from '../client';

const WatWeDoenPage = () => {
  const [content, setContent] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      const result = await client.getEntries({
        content_type: 'watWeDoen',
        include: 10,
      });
      setContent(result.items[0].fields);
    };
    fetchData();
  }, []);

  return (
    <>
      {content
        && (
          <>
            <Header
              title={content.header.fields.hoofdtitel}
              subTitle={content.header.fields.subtitel && content.header.fields.subtitel}
            />
            <Introduction
              title={content.introductie.fields.titel}
              text={content.introductie.fields.introductie}
            />
            <HighLight
              title={content.highlightBlokTitel}
              items={content.highlightBlokArtikelen}
            />
            <Info title={content.vragenBlok.fields.titel}>
              <Harmonica items={content.vragenBlok.fields.vragen} />
            </Info>
            <Testimonial
              testimonial={content.testimonialBlok.fields.testimonial}
              author={content.testimonialBlok.fields.naam}
              age={content.testimonialBlok.fields.leeftijd}
              buttonLabel={content.testimonialButton.fields.linkTekst}
              buttonUrl={content.testimonialButton.fields.linkUrl}
            />
            <Info title={content.voorlichting.fields.titelLinks}>
              <h2>{content.voorlichting.fields.koptekst1}</h2>
              {content.voorlichting.fields.koptekst2
                && <h2>{content.voorlichting.fields.koptekst2}</h2>}
              <ParagraphBig>
                { content.voorlichting.fields.paragraaf }
              </ParagraphBig>
              <ActionBar
                buttonlLabel={content.voorlichting.fields.button
                  && content.voorlichting.fields.button.fields.linkTekst}
                buttonLinkTo={content.voorlichting.fields.button
                  && content.voorlichting.fields.button.fields.linkUrl}
                anchorLabel={content.voorlichting.fields.link
                  && content.voorlichting.fields.link.fields.linkTekst}
                anchorLinkTo={content.voorlichting.fields.link
                  && content.voorlichting.fields.link.fields.linkUrl}
              />
            </Info>
            <Footer />
          </>
        )}
    </>
  );
};

export default WatWeDoenPage;
