import styled from 'styled-components';
import arrowDown from '../../assets/svgs/arrow-down.png';
import { ButtonElement } from '../Button';

export const TextInputElement = styled.input`
  width: 100%;
  border: 1px solid ${(props) => props.theme.colors.blueLight};
  height: 50px;
  box-sizing: border-box;
  font-size: 18px;
  padding: 0 17px;
  margin: 0 0 8px 0;
`;

export const CheckboxInputElement = styled.input`
    display: none;
`;

export const CheckMark = styled.span`
  cursor: pointer;
  position: absolute;
  display: ${(props) => props.active ? 'block' : 'none'};
  left: 6px;
  top: 0px;
  width: 6px;
  height: 13px;
  border: solid ${(props) => props.theme.colors.blueLight};
  border-width: 0 4px 4px 0;
  transform: rotate(45deg);
`;

export const LabelElement = styled.label`
    display: flex;
    position: relative;
    margin: 8px 0 0;
    cursor: pointer;
    
    &:before {
        content: '';
        display: inline-block;
        width: 20px;
        height: 20px;
        border: 1px solid ${(props) => props.theme.colors.blueLight};
        margin: 0 8px 0 0;
    }
`;

export const DoubleInputRow = styled.span`
  display: flex;
  flex-direction: column;

  & > ${TextInputElement}:first-of-type {
    margin-right: 8px;
  }

  @media (min-width: ${(props) => props.theme.breakpoints.sm}) {
    flex-direction: row;
  }
`;

export const TextAreaElement = styled.textarea`
  width: 100%;
  border: 1px solid ${(props) => props.theme.colors.blueLight};
  box-sizing: border-box;
  font-size: 18px;
  padding: 17px;
  font-family: acumin-pro, Helvetica, sans-serif;
`;

export const SelectElement = styled.select`
  width: 100%;
  color: #757575;
  border: 1px solid ${(props) => props.theme.colors.blueLight};
  height: 50px;
  box-sizing: border-box;
  font-size: 18px;
  padding: 0 17px;
  margin: 0 0 8px 0;
  appearance: none;
  background: url(${arrowDown}) no-repeat right;
  background-position-x: 97%;
`;

export const Form = styled.form`
  height: 100%;
  width: 100%;
`;

export const SubmitButton = styled(ButtonElement)`
  width: 100%;
  margin: 20px 0 0 0;
`;

export const PrivacyStatement = styled.p`
  font-size: 12px;
`;

export const ErrorMessage = styled.p`
  color: red;
  width: 100%;
  text-align: center;
  margin: 4px 0 0 0;
`;

export const SubmitSuccesContainer = styled.div`
  width: 100%;
  height: 100%;
  background-color: #f7f7f7;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
