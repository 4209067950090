import React, { useEffect, useState } from 'react';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { INLINES, BLOCKS } from '@contentful/rich-text-types';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import Footer from '../components/Footer';
import Navigation from '../components/Navigation';
import {
  ContentContainer,
  GlobalContainer,
  NavigationContainer,
} from '../components/Container';
import {
  TwoColumnGrid,
  Column,
} from '../layouts/TwoColumnGrid';
import { InschrijvenForm } from '../components/forms';
import { client } from '../client';

const LocationPhoto = styled.img`
  width: 100%;
`;

const LocationPhotoWrapper = styled.div`
  height: 240px;
  overflow: hidden;
  margin: ${(props) => props.theme.constants.defaultItems} 0;
`;

const ParagraphNoMargin = styled.p`
  margin: 0;
`;

export const options = {
  renderNode: {
    [INLINES.EMBEDDED_ENTRY]: (node) => {
      const { linkTekst, linkUrl } = node.data.target.fields;
      return <Link to={linkUrl}>{ linkTekst }</Link>;
    },
    [BLOCKS.EMBEDDED_ASSET]: (node) => {
      const { title, file } = node.data.target.fields;
      return (
        <LocationPhotoWrapper>
          <LocationPhoto alt={title} src={file.url} />
        </LocationPhotoWrapper>
      );
    },
    [BLOCKS.PARAGRAPH]: (node, children) => <ParagraphNoMargin>{children}</ParagraphNoMargin>,
  },
  renderText: (text) => (
    text.split('\n').reduce((children, textSegment, index) => ([
      ...children,
      index > 0 && <br key={`${index}-linebreak`} />,
      textSegment,
    ]), [])
  ),
};

const InschrijvenVoorlichtingPage = () => {
  const [content, setContent] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      const result = await client.getEntries({
        content_type: 'inschrijvenVoorlichtingPage',
        include: 10,
      });
      setContent(result.items[0].fields);
    };
    fetchData();
  }, []);

  return (
    <>
      {content
        && (
          <>
            <GlobalContainer>
              <NavigationContainer>
                <Navigation inverted />
              </NavigationContainer>
            </GlobalContainer>
            <GlobalContainer>
              <ContentContainer>
                <TwoColumnGrid>
                  <Column>
                    {documentToReactComponents(content.beschrijving, options)}
                  </Column>
                  <Column>
                    <InschrijvenForm
                      type="voorlichting"
                      eventData={{
                        placeholder: content.formulierWaardes.fields.selectOptiesPlaceholder,
                        options: content.formulierWaardes.fields.selectOpties,
                      }}
                      firstnamePlaceholder="Voornaam"
                      surnamePlaceholder="Achternaam"
                      dateOfBirthPlaceholder="Geboortedatum"
                      emailPlaceholder="Email adres"
                      phonePlaceholder="Telefoonnummer"
                      peoplePlaceholder="Met hoeveel mensen kom je naar de voorlichting?"
                      remarksPlaceholder={content.formulierWaardes.fields.opmerkingenPlaceholder}
                      errorMessage={content.formulierWaardes.fields.foutmelding}
                      succesMessage={content.formulierWaardes.fields.succesmelding}
                      disclaimer={content.formulierWaardes.fields.disclaimer}
                      buttonLabel={content.formulierWaardes.fields.buttonTekst}
                    />
                  </Column>
                </TwoColumnGrid>
              </ContentContainer>
            </GlobalContainer>
            <Footer />
          </>
        )}
    </>
  );
};

export default InschrijvenVoorlichtingPage;
