import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import Header from '../layouts/Header';
import Footer from '../components/Footer';
import {
  ContentContainer,
  GlobalContainer,
} from '../components/Container';
import { client } from '../client';
import headerShopImage from '../assets/photos/header-shop-background.jpg';
import Introduction from '../layouts/Introduction';
import Testimonial from '../layouts/Testimonial';
import ParagraphBig from '../components/ParagraphBig';
import ActionBar from '../components/ActionBar';
import Info from '../layouts/Info';

const Title = styled.h2`
  width: 100%;
  grid-area: 1 / 1 / 2 / 13;
  display: flex;
  justify-content: center;
`;

const ShopPage = () => {
  const [content, setContent] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      const result = await client.getEntries({
        content_type: 'shopPage',
        include: 10,
      });
      setContent(result.items[0].fields);
      console.log(result.items[0].fields);
    };
    fetchData();
  }, []);

  return (
    <>
      {content
        && (
          <>
            <Header
              title={content.header.fields.hoofdtitel}
              subTitle={content.header.fields.subtitel && content.header.fields.subtitel}
              backgroundUrl={headerShopImage}
            />
            <Introduction
              title={content.introductieBlok.fields.titel}
              text={content.introductieBlok.fields.introductie}
            />
            <Testimonial
              testimonial={content.testimonialBlok.fields.testimonial}
              author={content.testimonialBlok.fields.naam}
              age={content.testimonialBlok.fields.leeftijd}
              buttonLabel={content.testimonialButton.fields.linkTekst}
              buttonUrl={content.testimonialButton.fields.linkUrl}
            />
            <Info title={content.voorlichting.fields.titelLinks}>
              <h2>{content.voorlichting.fields.koptekst1}</h2>
              {content.voorlichting.fields.koptekst2
                  && <h2>{content.voorlichting.fields.koptekst2}</h2>}
              <ParagraphBig>
                { content.voorlichting.fields.paragraaf }
              </ParagraphBig>
              <ActionBar
                  buttonlLabel={content.voorlichting.fields.button
                      && content.voorlichting.fields.button.fields.linkTekst}
                  buttonLinkTo={content.voorlichting.fields.button
                      && content.voorlichting.fields.button.fields.linkUrl}
                  anchorLabel={content.voorlichting.fields.link
                      && content.voorlichting.fields.link.fields.linkTekst}
                  anchorLinkTo={content.voorlichting.fields.link
                      && content.voorlichting.fields.link.fields.linkUrl}
              />
            </Info>
            <Footer />
          </>
        )}
    </>
  );
};

export default ShopPage;
