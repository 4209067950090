import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import Header from '../layouts/Header';
import Info from '../layouts/Info';
import {
  ThreeColumnGridContainer,
  Column,
} from '../layouts/ThreeColumnGrid';
import urlConstants from '../constants/urlConstants';
import Testimonial from '../layouts/Testimonial';
import ParagraphBig from '../components/ParagraphBig';
import ActionBar from '../components/ActionBar';
import Footer from '../components/Footer';
import Button from '../components/Button';
import VideoImageGrid from '../layouts/VideoImageGrid';
import HighLight from '../layouts/Highlight';
import Harmonica from '../components/Harmonica';
import ImageOverlay from '../components/ImageOverlay';
import Introduction from '../layouts/Introduction';
import {
  ContentContainer,
  GlobalContainer,
} from '../components/Container';
import headerResultatenImage from '../assets/photos/header-resultaten-background.jpg';
import { client } from '../client';

const ActionBarText = styled.div`
  grid-area: 3 / 1 / 4 / 13;
  margin: 24px 0 0;
  
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  & button {
    width: 100%;
    margin: 0 0 ${(props) => props.theme.constants.defaultItems} 0;
  }

  @media (min-width: ${(props) => props.theme.breakpoints.md}) {
    display: flex;
    flex-direction: row;
    align-items: center;

    & button {
      width: auto;
      margin: 0 0 0 ${(props) => props.theme.constants.defaultItems};
    }
  }
`;

const ActionBarWrapper = styled.div`
  grid-area: 3 / 1 / 4 / 13;
  margin: ${(props) => props.theme.constants.defaultContentMobile} 0 0;

  @media (min-width: ${(props) => props.theme.breakpoints.sm}) {
    margin: ${(props) => props.theme.constants.defaultContent} 0 0;
  }
`;

const ImageGrid = styled.div`
  grid-area: 4 / 1 / 5 / 13;
`;

const ImageColumn = styled(Column)`
  max-height: 330px;
  overflow: hidden;

  @media (min-width: ${(props) => props.theme.breakpoints.md}) {
    height: 390px;
  }
`;

const ReviewsPage = () => {
  const [content, setContent] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      const result = await client.getEntries({
        content_type: 'reviewsPage',
        include: 10,
      });
      setContent(result.items[0].fields);
    };
    fetchData();
  }, []);

  return (
    <>
      {content
        && (
          <>
            <Header
              title={content.header.fields.hoofdtitel}
              subTitle={content.header.fields.subtitel && content.header.fields.subtitel}
              backgroundUrl={headerResultatenImage}
            />
            <Introduction
              title={content.introductieBlok.fields.titel}
              text={content.introductieBlok.fields.introductie}
            />
            <GlobalContainer>
              <ContentContainer sequence>
                <VideoImageGrid items={content.informatieMediaGrid1} />
                <ActionBarText>
                  <ParagraphBig>
                    {content.informatieTekst}
                  </ParagraphBig>
                  <Button
                    label={content.informatieButton.fields.linkTekst}
                    linkTo={urlConstants.VOORLICHTING}
                  />
                </ActionBarText>
                <ImageGrid>
                  <ThreeColumnGridContainer>
                    {content.informatieAfbeeldingGrid1.map((item) => (
                      <ImageColumn key={item.sys.id}>
                        <ImageOverlay
                          image={item.fields.afbeelding && item.fields.afbeelding.fields.file.url}
                          author={item.fields.naam}
                          age={item.fields.leeftijd}
                          text={item.fields.testimonial}
                        />
                      </ImageColumn>
                    ))}
                  </ThreeColumnGridContainer>
                </ImageGrid>
              </ContentContainer>
            </GlobalContainer>
            <Info
              sequence
              title={content.voorNaBlok.fields.titel}
            >
              <Harmonica items={content.voorNaBlok.fields.vragen} />
            </Info>
            <Testimonial
              testimonial={content.testimonialBlok.fields.testimonial}
              author={content.testimonialBlok.fields.naam}
              age={content.testimonialBlok.fields.leeftijd}
              buttonLabel={content.testimonialButton.fields.linkTekst}
              buttonUrl={content.testimonialButton.fields.linkUrl}
            />
            <Info title={content.verhalenBlok.fields.titel}>
              <Harmonica items={content.verhalenBlok.fields.vragen} />
            </Info>
            <GlobalContainer>
              <ContentContainer sequence>
                <VideoImageGrid items={content.informatieMediaGrid2} />
                <ActionBarWrapper>
                  <ActionBar
                    buttonlLabel={content.informatieButton2.fields.linkTekst}
                    buttonLinkTo={urlConstants.VOORLICHTING}
                    anchorLabel={content.informatieLink.fields.linkTekst}
                    anchorLinkTo={urlConstants.WATWEDOEN}
                  />
                </ActionBarWrapper>
              </ContentContainer>
            </GlobalContainer>
            <HighLight
              title={content.highlightBlokTitel}
              items={content.highlightBlokTestimonials}
              textOnly
            />
            <Info title={content.voorlichting.fields.titelLinks}>
              <h2>{content.voorlichting.fields.koptekst1}</h2>
              {content.voorlichting.fields.koptekst2
                && <h2>{content.voorlichting.fields.koptekst2}</h2>}
              <ParagraphBig>
                { content.voorlichting.fields.paragraaf }
              </ParagraphBig>
              <ActionBar
                buttonlLabel={content.voorlichting.fields.button
                  && content.voorlichting.fields.button.fields.linkTekst}
                buttonLinkTo={content.voorlichting.fields.button
                  && content.voorlichting.fields.button.fields.linkUrl}
                anchorLabel={content.voorlichting.fields.link
                  && content.voorlichting.fields.link.fields.linkTekst}
                anchorLinkTo={content.voorlichting.fields.link
                  && content.voorlichting.fields.link.fields.linkUrl}
              />
            </Info>
            <Footer />
          </>
        )}
    </>
  );
};

export default ReviewsPage;
