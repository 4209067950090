import React from 'react';
import styled, { css } from 'styled-components';

const EmDashElement = styled.p`
  margin: 0;
  color: ${(props) => (props.dark ? props.theme.colors.black : props.theme.colors.white)};

  ${(props) => (props.after
    ? css`
      &::after {
        display: inline-block;
        content: '\\2014';
        padding-left: 8px;
      }
    `
    : css`
      &::before {
        display: inline-block;
        content: '\\2014';
        padding-right: 8px;
      }
    `)};
`;

const EmDashTitle = ({
  title,
  after,
  dark,
  className,
}) => (
  <EmDashElement
    after={after}
    dark={dark}
    className={className}
  >
    { title }
  </EmDashElement>
);

export default EmDashTitle;
