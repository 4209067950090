import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import Button from './Button';

const ActionBarWrapper = styled.span`
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  & button + h6 {
    margin: ${(props) => props.theme.constants.defaultItems} 0 0 0;
  }

  & button {
    width: 100%;
  }

  @media (min-width: ${(props) => props.theme.breakpoints.md}) {
    flex-direction: row;
    align-items: center;

    & button + h6 {
      margin: 0 0 0 ${(props) => props.theme.constants.defaultItems};
    }

    & button {
      width: auto;
    }
  }
`;

const ActionBar = ({
  buttonlLabel,
  buttonLinkTo,
  anchorLabel,
  anchorLinkTo,
}) => (
  <ActionBarWrapper>
    {buttonlLabel && (
    <Button
      label={buttonlLabel}
      linkTo={buttonLinkTo}
    />
    )}
    {anchorLabel
      && (
      <h6>
        <Link to={anchorLinkTo}>
          { anchorLabel }
        </Link>
      </h6>
      )}
  </ActionBarWrapper>
);

export default ActionBar;
