import React, { useEffect, useState } from 'react';
import ActionBar from '../components/ActionBar';
import Header from '../layouts/Header';
import Info from '../layouts/Info';
import ParagraphBig from '../components/ParagraphBig';
import Harmonica from '../components/Harmonica';
import HighLight from '../layouts/Highlight';
import Footer from '../components/Footer';
import Testimonial from '../layouts/Testimonial';
import Introduction from '../layouts/Introduction';
import { client } from '../client';

const VoorScholenPage = () => {
  const [content, setContent] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      const result = await client.getEntries({
        content_type: 'voorScholenPage',
        include: 10,
      });
      setContent(result.items[0].fields);
    };
    fetchData();
  }, []);

  return (
    <>
      { content
          && (
            <>
              <Header
                title={content.header.fields.hoofdtitel}
                subTitle={content.header.fields.subtitel && content.header.fields.subtitel}
              />
              <Introduction
                title={content.introductieBlok.fields.titel}
                text={content.introductieBlok.fields.introductie}
              />
              <HighLight
                title={content.highlightBlokTitel}
                items={content.highlightBlokArtikelen}
              />
              <Info title={content.vragenBlok.fields.titel}>
                <Harmonica items={content.vragenBlok.fields.vragen} />
              </Info>
              <Testimonial
                testimonial={content.testimonialBlok.fields.testimonial}
                author={content.testimonialBlok.fields.naam}
                age={content.testimonialBlok.fields.leeftijd}
                buttonLabel={content.testimonialButton.fields.linkTekst}
                buttonUrl={content.testimonialButton.fields.linkUrl}
              />
              <Info title={content.voorlichting.fields.titelLinks}>
                <h2>{content.voorlichting.fields.koptekst1}</h2>
                {content.voorlichting.fields.koptekst2
                  && <h2>{content.voorlichting.fields.koptekst2}</h2>}
                <ParagraphBig>
                  { content.voorlichting.fields.paragraaf }
                </ParagraphBig>
                <ActionBar
                  buttonlLabel={content.voorlichting.fields.button
                    && content.voorlichting.fields.button.fields.linkTekst}
                  buttonLinkTo={content.voorlichting.fields.button
                    && content.voorlichting.fields.button.fields.linkUrl}
                  anchorLabel={content.voorlichting.fields.link
                    && content.voorlichting.fields.link.fields.linkTekst}
                  anchorLinkTo={content.voorlichting.fields.link
                    && content.voorlichting.fields.link.fields.linkUrl}
                />
              </Info>
              <Footer />
            </>
          )}
    </>
  );
};

export default VoorScholenPage;
