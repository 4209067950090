import React, { useEffect, useState } from 'react';
import Header from '../layouts/Header';
import Footer from '../components/Footer';
import Introduction from '../layouts/Introduction';
import { client } from '../client';

const GedragscodePage = () => {
  const [content, setContent] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      const result = await client.getEntries({
        content_type: 'gedragscodePage',
        include: 10,
      });
      setContent(result.items[0].fields);
    };
    fetchData();
  }, []);

  return (
    <>
      {content
        && (
          <>
            <Header
              title={content.header.fields.hoofdtitel}
              subTitle={content.header.fields.subtitel && content.header.fields.subtitel}
            />
            <Introduction
              title={content.introductieBlok.fields.titel}
              text={content.introductieBlok.fields.introductie}
            />
            {/* <Info title={content.introductieBlok.fields.titel}>
              <ParagraphBig noMargin>
                { content.introductieBlok.fields.subtekst }
              </ParagraphBig>
              <ContactBlock>
                <Link to={urlConstants.CONTACT}>
                  { content.introductieBlok.fields.meerInformatieLink.fields.linkTekst }
                </Link>
              </ContactBlock>
              <Harmonica items={content.vragenBlok.fields.vragen} />
            </Info> */}
            <Footer />
          </>
        )}
    </>
  );
};

export default GedragscodePage;
