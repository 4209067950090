import React, { useState, useEffect } from 'react';
import {
  TextInputElement,
  DoubleInputRow,
  TextAreaElement,
  Form,
  SubmitButton,
  PrivacyStatement,
  ErrorMessage,
  SubmitSuccesContainer,
  SelectElement,
  LabelElement,
  CheckMark,
  CheckboxInputElement,
} from './styledElements';
import { ButtonElement } from '../Button';

const encode = (data) => (
  Object
    .keys(data)
    .map((key) => `${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`)
    .join('&')
);

// Remember to also change the fake form in index.html so netlify recieves the entries
const InschrijvenForm = ({
  type,
  firstnamePlaceholder,
  surnamePlaceholder,
  dateOfBirthPlaceholder,
  emailPlaceholder,
  phonePlaceholder,
  peoplePlaceholder,
  remarksPlaceholder,
  eventData,
  errorMessage,
  succesMessage,
  disclaimer,
  buttonLabel,
}) => {
  const [selectedEvent, setSelectedEvent] = useState('');
  const [firstname, setFirstname] = useState('');
  const [surname, setSurname] = useState('');
  const [dateOfBirth, setDateOfBirth] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [people, setPeople] = useState('');
  const [knowThrough, setKnowThrough] = useState('');
  const [remarks, setRemarks] = useState('');
  const [termsAndConditions, setTermsAndConditions] = useState(false);
  const [loading, toggleLoading] = useState(false);
  const [successfulSubmission, setSuccessfulSubmission] = useState(false);
  const [failedSubmission, setFailedSubmission] = useState(false);
  const [requiredFieldsFilled, setRequiredFieldsFilled] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setFailedSubmission(false);

    if (!requiredFieldsFilled) {
      setFailedSubmission(true);
      return;
    }

    toggleLoading(true);

    let formData = {
      'form-name': 'inschrijven',
      event: selectedEvent,
      firstname,
      surname,
      dateOfBirth,
      email,
      phone,
      knowThrough,
      remarks,
    };

    if (type === 'training') {
      formData = {
        ...formData,
        termsAndConditions,
      };
    }

    if (type === 'voorlichting') {
      formData = {
        ...formData,
        people,
      };
    }

    console.log(formData);

    try {
      await fetch(
        '/',
        {
          method: 'POST',
          headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
          body: encode(formData),
        },
      );

      setSuccessfulSubmission(true);
      toggleLoading(false);
    } catch (error) {
      setFailedSubmission(true);
      toggleLoading(false);
    }
  };

  const resetForm = () => {
    setSuccessfulSubmission(false);
    setFirstname('');
    setSurname('');
    setDateOfBirth('');
    setEmail('');
    setPhone('');
    setRemarks('');
    setPeople('');
    setKnowThrough('');
    setTermsAndConditions(false);
    setSelectedEvent('');
    toggleLoading(false);
  };

  const handleEventChange = (e) => {
    setSelectedEvent(e.target.value);
  };

  useEffect(() => {
    if (
      selectedEvent !== ''
      && firstname !== ''
      && surname !== ''
      && dateOfBirth !== ''
      && email !== ''
      && phone !== ''
    ) {
      setRequiredFieldsFilled(true);
    }
  }, [selectedEvent, firstname, surname, dateOfBirth, email, phone]);

  return (
    <>
      {!successfulSubmission
        && (
          <Form onSubmit={handleSubmit}>
            <SelectElement
              value={selectedEvent}
              onChange={handleEventChange}
              name="event"
            >
              <option value="">{eventData.placeholder}</option>
              {eventData.options.map((item) => (
                <option key={item.sys.id} value={item.fields.optieWaarde}>
                  { item.fields.optieLabel }
                </option>
              ))}
            </SelectElement>
            <DoubleInputRow>
              <TextInputElement
                type="text"
                name="firstname"
                value={firstname}
                placeholder={firstnamePlaceholder}
                onChange={(e) => setFirstname(e.target.value)}
              />
              <TextInputElement
                type="text"
                name="surname"
                value={surname}
                placeholder={surnamePlaceholder}
                onChange={(e) => setSurname(e.target.value)}
              />
            </DoubleInputRow>
            <TextInputElement
              type="text"
              name="dateOfBirth"
              value={dateOfBirth}
              placeholder={dateOfBirthPlaceholder}
              onChange={(e) => setDateOfBirth(e.target.value)}
            />
            <TextInputElement
              type="email"
              name="email"
              value={email}
              placeholder={emailPlaceholder}
              onChange={(e) => setEmail(e.target.value)}
            />
            <TextInputElement
              type="text"
              name="phone"
              value={phone}
              placeholder={phonePlaceholder}
              onChange={(e) => setPhone(e.target.value)}
            />
            {type === 'voorlichting'
              && (
              <TextInputElement
                type="number"
                name="people"
                value={people}
                min="1"
                placeholder={peoplePlaceholder}
                onChange={(e) => setPeople(e.target.value)}
              />
              )}
            <TextInputElement
              type="text"
              name="knowThrough"
              value={knowThrough}
              placeholder="Ik ken InsideOut Nederland via..."
              onChange={(e) => setKnowThrough(e.target.value)}
            />
            <TextAreaElement
              name="remarks"
              rows="8"
              value={remarks}
              placeholder={remarksPlaceholder}
              onChange={(e) => setRemarks(e.target.value)}
            />
            {type === 'training'
            && (
            <LabelElement htmlFor="terms-and-conditions">
              <CheckMark active={termsAndConditions} />
              <CheckboxInputElement
                type="checkbox"
                id="terms-and-conditions"
                name="termsAndConditions"
                value={termsAndConditions}
                onChange={() => setTermsAndConditions(!termsAndConditions)}
              />
              Ik ga akkoord met de algemene voorwaarden en de annuleringsregeling
            </LabelElement>
            )}
            <SubmitButton
              type="submit"
              disabled={loading}
            >
              {loading ? 'Bezig met versturen...' : buttonLabel}
            </SubmitButton>

            {failedSubmission && !requiredFieldsFilled
            && <ErrorMessage>Niet alles is ingevuld. Vul alsjeblieft alle velden in.</ErrorMessage>}

            {failedSubmission && requiredFieldsFilled
            && <ErrorMessage>{ errorMessage }</ErrorMessage>}

            <PrivacyStatement>{ disclaimer }</PrivacyStatement>
          </Form>
        )}
      {successfulSubmission
        && (
        <SubmitSuccesContainer>
          <p>{ succesMessage }</p>
          <ButtonElement
            type="button"
            onClick={resetForm}
          >
            Verstuur er nog één
          </ButtonElement>
        </SubmitSuccesContainer>
        )}
    </>
  );
};

export default InschrijvenForm;
