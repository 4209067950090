const urlConstants = {
  HOME: '/',
  WATWEDOEN: '/wat-we-doen',
  WIEWEZIJN: '/wie-we-zijn',
  VRAGEN: '/vragen',
  YOUNGLEADERS: '/youngleaders',
  VOORLICHTING: '/inschrijven-voorlichting',
  TRAINING: '/inschrijven-training',
  RESULTATEN: '/resultaten',
  OPVOEDERS: '/voor-opvoeders',
  SCHOLEN: '/voor-scholen',
  VOLWASSENEN: '/voor-volwassenen',
  GEDRAGSCODE: '/gedragscode',
  CONTACT: '/contact',
  DATA: '/data',
  PARTNERS: '/partners',
  PRIVACY: '/privacy',
  VOORWAARDEN: '/algemene-voorwaarden',
};

export default urlConstants;
