import React from 'react';
import styled, { css } from 'styled-components';

const ParagraphBigElement = styled.p`
  font-size: 24px;
  line-height: 1;

  @media (min-width: ${(props) => props.theme.breakpoints.sm}) {
    font-size: 30px;
  }

  ${(props) => props.noMargin && css`
    margin: 0;
  `};

  ${(props) => props.noMarginBottom && css`
    margin-bottom: 0;
  `};
`;

const ParagraphBig = ({
  noMargin,
  noMarginBottom,
  children,
}) => (
  <ParagraphBigElement
    noMargin={noMargin}
    noMarginBottom={noMarginBottom}
  >
    { children }
  </ParagraphBigElement>
);

export default ParagraphBig;
