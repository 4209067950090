const theme = {
  colors: {
    text: '#190B28',
    primary: 'hsl(57, 100%, 68%)', // #FFF65C
    primaryLight: 'hsl(57, 100%, 73%)',
    secondary: 'hsl(320, 77%, 56%)',
    tertiary: 'hsl(184, 44%, 52%)',
    blue: 'hsl(204, 81%, 46%)',
    blueLight: '#52C2C8',
    black: 'hsl(0, 0%, 0%)',
    white: 'hsl(0, 0%, 100%)',
  },
  constants: {
    defaultContent: '60px',
    defaultContentMobile: '30px',
    defaultBlock: '100px',
    defaultBlockMobile: '50px',
    defaultItems: '30px',
    defaultItemsSmall: '15px',
  },
  breakpoints: {
    sm: '480px',
    md: '768px',
    lg: '992px',
    xl: '1200px',
  },
};

export default theme;
