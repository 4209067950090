import React, { useEffect, useState } from 'react';
import Header from '../layouts/Header';
import Info from '../layouts/Info';
import ParagraphBig from '../components/ParagraphBig';
import ActionBar from '../components/ActionBar';
import Footer from '../components/Footer';
import Harmonica from '../components/Harmonica';
import headerVragenImage from '../assets/photos/header-vragen-background.jpg';
import { client } from '../client';

const VragenPage = () => {
  const [content, setContent] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      const result = await client.getEntries({
        content_type: 'vragenPage',
        include: 10,
      });
      setContent(result.items[0].fields);
    };
    fetchData();
  }, []);

  return (
    <>
      { content
          && (
            <>
              <Header
                title={content.header.fields.hoofdtitel}
                subTitle={content.header.fields.subtitel && content.header.fields.subtitel}
                backgroundUrl={headerVragenImage}
              />
              <Info title={content.vragenblok1.fields.titel}>
                <Harmonica items={content.vragenblok1.fields.vragen} />
              </Info>
              <Info
                title={content.vragenblok2.fields.titel}
                sequence
              >
                <Harmonica items={content.vragenblok2.fields.vragen} />
              </Info>
              {content.vragenblok3 && (
                <Info
                  title={content.vragenblok3.fields.titel}
                  sequence
                >
                  <Harmonica items={content.vragenblok3.fields.vragen} />
                </Info>
              )}
              {content.vragenblok4 && (
                <Info
                  title={content.vragenblok4.fields.titel}
                  sequence
                >
                  <Harmonica items={content.vragenblok4.fields.vragen} />
                </Info>
              )}
              {content.vragenblok5 && (
                <Info
                  title={content.vragenblok5.fields.titel}
                  sequence
                >
                  <Harmonica items={content.vragenblok5.fields.vragen} />
                </Info>
              )}
              {content.vragenblok6 && (
                <Info
                  title={content.vragenblok6.fields.titel}
                  sequence
                >
                  <Harmonica items={content.vragenblok6.fields.vragen} />
                </Info>
              )}
              {content.vragenblok7 && (
                <Info
                  title={content.vragenblok7.fields.titel}
                  sequence
                >
                  <Harmonica items={content.vragenblok7.fields.vragen} />
                </Info>
              )}
              <Info sequence title={content.voorlichting.fields.titelLinks}>
                <h2>{content.voorlichting.fields.koptekst1}</h2>
                {content.voorlichting.fields.koptekst2
                  && <h2>{content.voorlichting.fields.koptekst2}</h2>}
                <ParagraphBig>
                  { content.voorlichting.fields.paragraaf }
                </ParagraphBig>
                <ActionBar
                  buttonlLabel={content.voorlichting.fields.button
                    && content.voorlichting.fields.button.fields.linkTekst}
                  buttonLinkTo={content.voorlichting.fields.button
                    && content.voorlichting.fields.button.fields.linkUrl}
                  anchorLabel={content.voorlichting.fields.link
                    && content.voorlichting.fields.link.fields.linkTekst}
                  anchorLinkTo={content.voorlichting.fields.link
                    && content.voorlichting.fields.link.fields.linkUrl}
                />
              </Info>
              <Footer />
            </>
          )}
    </>
  );
};

export default VragenPage;
