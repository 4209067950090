import React from 'react';
import styled from 'styled-components';
import ImageOverlay from '../components/ImageOverlay';
import VideoOverlay from '../components/VideoOverlay';

const GridSection = styled.div`
  grid-area: 2 / 1 / 3 / 13;

  display: grid;
  width: 100%;
  grid-template-columns: 5fr 2fr 5fr;
  grid-column-gap: 8px;
  grid-row-gap: ${(props) => props.theme.constants.defaultContentMobile};

  @media (min-width: ${(props) => props.theme.breakpoints.md}) {
    grid-column-gap: ${(props) => props.theme.constants.defaultContent};
    grid-row-gap: ${(props) => props.theme.constants.defaultContent};
  }
`;

const Blok = styled.div`
  height: 190px;
  width: 100%;
  overflow: hidden;
  position: relative;

  @media (min-width: ${(props) => props.theme.breakpoints.md}) {
    height: 388px;
  }

  @media (min-width: ${(props) => props.theme.breakpoints.lg}) {
    height: 289px;
  }

  @media (min-width: ${(props) => props.theme.breakpoints.xl}) {
    height: 388px;
  }
`;

const FirstBlock = styled(Blok)`
  height: 300px;
  background-color: ${(props) => props.theme.colors.primary};
  grid-area: 1 / 1 / 2 / 13;

  @media (min-width: ${(props) => props.theme.breakpoints.lg}) {
    grid-area: 1 / 1 / 2 / 2;
  }
`;

const SecondBlock = styled(Blok)`
  grid-area: 2 / 1 / 3 / 13;

  @media (min-width: ${(props) => props.theme.breakpoints.lg}) {
    grid-area: 1 / 2 / 1 / 4;
  }
`;

const ThirdBlock = styled(Blok)`
  grid-area: 3 / 1 / 4 / 13;

  @media (min-width: ${(props) => props.theme.breakpoints.lg}) {
    grid-area: 2 / 1 / 3 / 3;
  }
`;

const FourthBlock = styled(Blok)`
  height: 300px;
  background-color: ${(props) => props.theme.colors.primary};
  grid-area: 4 / 1 / 5 / 13;

  @media (min-width: ${(props) => props.theme.breakpoints.lg}) {
    grid-area: 2 / 3 / 3 / 4;
  }
`;

const VideoImageGridLayout = ({
  items,
}) => {
  const images = items.filter((item) => item.fields.type === 'Tekst met afbeelding');
  const videos = items.filter((item) => item.fields.type === 'Video');

  return (
    <>
      <GridSection>
        <FirstBlock>
          <ImageOverlay
            text={images[0].fields.testimonial}
            image={images[0].fields.afbeelding && images[0].fields.afbeelding.fields.file.url}
            author={images[0].fields.naam}
            age={images[0].fields.leeftijd}
          />
        </FirstBlock>
        <SecondBlock>
          <VideoOverlay
            videoId={videos[0].fields.videoId}
            image={videos[0].fields.afbeelding.fields.file.url}
            author={videos[0].fields.naam}
            age={videos[0].fields.leeftijd}
          />
        </SecondBlock>
        <ThirdBlock>
          <VideoOverlay
            videoId={videos[1].fields.videoId}
            image={videos[1].fields.afbeelding.fields.file.url}
            author={videos[1].fields.naam}
            age={videos[1].fields.leeftijd}
          />
        </ThirdBlock>
        <FourthBlock>
          <ImageOverlay
            text={images[1].fields.testimonial}
            image={images[1].fields.afbeelding && images[1].fields.afbeelding.fields.file.url}
            author={images[1].fields.naam}
            age={images[1].fields.leeftijd}
          />
        </FourthBlock>
      </GridSection>
    </>
  );
};

export default VideoImageGridLayout;
