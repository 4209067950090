import React from 'react';
import styled from 'styled-components';

const ButtonElement = styled.button`
  display: block;
  background-color: ${(props) => props.theme.colors.primary};
  border: 2px solid ${(props) => props.theme.colors.black};
  font-weight: 600;
  font-size: 18px;
  border-radius: 50%;
  padding: 22px 26px;
  border-bottom: 8px solid;
  cursor: pointer;
  outline: none;

  &:hover {
    border-bottom: 4px solid;
    transform: translateY(1px);
  }

  &::after {
    display: block;
    content: '';
    width: 0; 
    height: 0; 
    border-top: 13px solid transparent;
    border-bottom: 13px solid transparent;
    border-left: 18px solid ${(props) => props.theme.colors.black};
    margin-left: 4px;
    }
  }
`;

const ActionButton = ({ clickHandler }) => (
  <ButtonElement
    type="button"
    onClick={clickHandler}
  />
);

export default ActionButton;
