import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import Header from '../layouts/Header';
import Info from '../layouts/Info';
import Testimonial from '../layouts/Testimonial';
import ParagraphBig from '../components/ParagraphBig';
import ActionBar from '../components/ActionBar';
import Footer from '../components/Footer';
import {
  GlobalContainer,
  ContentContainer,
} from '../components/Container';
import {
  ThreeColumnGridContainer,
  Column,
} from '../layouts/ThreeColumnGrid';
import TextImage from '../components/TextImage';
import { client } from '../client';

const Team = styled.div`
  grid-area: 1 / 1 / 2 / 13;
`;

const TeamTitle = styled.h4`
  font-size: 32px;
  margin: 0;
`;

const ActivationBar = styled.span`
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  @media (min-width: ${(props) => props.theme.breakpoints.md}) {
    flex-direction: row;
    align-items: baseline;
  }

  & a {
    font-size: 24px;
    line-height: 0.9;
    font-weight: 400;
    margin-left: 0;

    @media (min-width: ${(props) => props.theme.breakpoints.md}) {
      margin-left: 12px;
      font-size: 30px;
    }
  }

  & a::after {
    width: 24px;
    height: 24px;
    top: 10px;
  }
`;

const WieWeZijnPage = () => {
  const [content, setContent] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      const result = await client.getEntries({
        content_type: 'wieWeZijnPage',
        include: 10,
      });
      setContent(result.items[0].fields);
    };
    fetchData();
  }, []);

  return (
    <>
      {content
        && (
          <>
            <Header
              title={content.header.fields.hoofdtitel}
              subTitle={content.header.fields.subtitel && content.header.fields.subtitel}
            />
            <GlobalContainer>
              <ContentContainer>
                <Team>
                  <TeamTitle>{ content.managementTitel }</TeamTitle>
                  <ThreeColumnGridContainer>
                    {content.managementLijst.map((item) => (
                      <Column key={item.sys.id}>
                        <TextImage
                          image={item.fields.foto.fields.file.url}
                          title={item.fields.naam}
                          text={item.fields.beschrijving}
                          subText={item.fields.functie}
                        />
                      </Column>
                    ))}
                    {content.managementLijst.length % 3 !== 0 && <Column />}
                  </ThreeColumnGridContainer>
                  <TeamTitle>{ content.coachesTitel }</TeamTitle>
                  <ThreeColumnGridContainer>
                    {content.coachLijst.map((item) => (
                      <Column key={item.sys.id}>
                        <TextImage
                          image={item.fields.foto.fields.file.url}
                          title={item.fields.naam}
                          text={item.fields.beschrijving}
                          subText={item.fields.functie}
                        />
                      </Column>
                    ))}
                    {content.coachLijst.length % 3 !== 0 && <Column />}
                  </ThreeColumnGridContainer>
                  <ActivationBar>
                    <ParagraphBig noMargin>
                      { content.coachTekst }
                    </ParagraphBig>
                    <h6>
                      <Link to={content.coachLink.fields.linkUrl}>
                        { content.coachLink.fields.linkTekst }
                      </Link>
                    </h6>
                  </ActivationBar>
                </Team>
              </ContentContainer>
            </GlobalContainer>
            <Testimonial
              testimonial={content.testimonialBlok.fields.testimonial}
              author={content.testimonialBlok.fields.naam}
              age={content.testimonialBlok.fields.leeftijd}
              buttonLabel={content.testimonialButton.fields.linkTekst}
              buttonUrl={content.testimonialButton.fields.linkUrl}
            />
            <Info title={content.voorlichting.fields.titelLinks}>
              <h2>{content.voorlichting.fields.koptekst1}</h2>
              {content.voorlichting.fields.koptekst2
                && <h2>{content.voorlichting.fields.koptekst2}</h2>}
              <ParagraphBig>
                { content.voorlichting.fields.paragraaf }
              </ParagraphBig>
              <ActionBar
                buttonlLabel={content.voorlichting.fields.button
                  && content.voorlichting.fields.button.fields.linkTekst}
                buttonLinkTo={content.voorlichting.fields.button
                  && content.voorlichting.fields.button.fields.linkUrl}
                anchorLabel={content.voorlichting.fields.link
                  && content.voorlichting.fields.link.fields.linkTekst}
                anchorLinkTo={content.voorlichting.fields.link
                  && content.voorlichting.fields.link.fields.linkUrl}
              />
            </Info>
            <Footer />
          </>
        )}
    </>
  );
};

export default WieWeZijnPage;
