import React from 'react';
// import { Link } from 'react-router-dom';
import styled from 'styled-components';
import {
  ContentContainer,
  GlobalContainer,
} from '../components/Container';
import Button from '../components/Button';
import ParagraphBig from '../components/ParagraphBig';
import EmDashTitle from '../components/EmDashTitle';
import quote from '../assets/svgs/quotation.svg';
import testimonialImage from '../assets/photos/header-testimonial-background.jpg';

const Testimonial = styled.article`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const TestimonialContent = styled.section`
  grid-area: 1 / 1 / 2 / 13;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  background-color: ${(props) => props.theme.colors.primary};
  padding: ${(props) => props.theme.constants.defaultContentMobile};

  @media (min-width: ${(props) => props.theme.breakpoints.sm}) {
    padding: ${(props) => props.theme.constants.defaultContent};
  }

  @media (min-width: ${(props) => props.theme.breakpoints.lg}) {
    grid-area: 1 / 1 / 2 / 10;
  }

  @media (min-width: ${(props) => props.theme.breakpoints.xl}) {
    grid-area: 1 / 1 / 2 / 8;
  }
`;

const Author = styled(EmDashTitle)`
  margin: 12px 0 ${(props) => props.theme.constants.defaultItems} 0;
`;

const PageContainer = styled(GlobalContainer)`
  background: url(${testimonialImage}) ${(props) => props.theme.colors.tertiary};
  background-size: cover;
`;

const TestimonialLayout = ({
  testimonial,
  author,
  age,
  buttonLabel,
  buttonUrl,
}) => (
  <PageContainer>
    <Testimonial>
      <ContentContainer>
        <TestimonialContent>
          <img alt="quotationmark" src={quote} />
          <ParagraphBig>
            { testimonial }
          </ParagraphBig>
          <Author dark title={`${author} ${age} jaar`} />
          <Button
            label={buttonLabel}
            linkTo={buttonUrl}
          />
        </TestimonialContent>
      </ContentContainer>
    </Testimonial>
  </PageContainer>
);

export default TestimonialLayout;
