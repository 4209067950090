import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import styled, { css } from 'styled-components';
import menuItems from '../constants/menuItems';
import footerImage from '../assets/photos/footer-background.jpg';

const MobileMenuElement = styled.div`
  box-sizing: border-box;
  overflow: hidden;
  position: absolute;
  top: 0;
  right: -100vw;
  width: 100%;
  height: 100vh;
  background: url(${footerImage}) ${(props) => props.theme.colors.black};
  z-index: 3;
  padding: 100px 30px 30px;
  display: flex;
  transition: right .2s ease-in-out;

  ${(props) => props.open && css` 
    right: 0;
    flex-direction: column;
  `}

  @media (min-width: ${(props) => props.theme.breakpoints.sm}) {
    display: none;
  }
`;

const MobileMenuLinkContainer = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
`;

const MobileMenuLink = styled.li`
  & a, 
  a:visited {
    color: ${(props) => props.theme.colors.primary};
    text-decoration: none;
    font-size: 30px;
    font-weight: 400;
    position: relative;
    display: inline-block;
  }

  ${(props) => props.active && css`
    & a::after {
      content: '';
      display: block;
      background-color: currentColor;
      height: 2px;
      margin-left: 0;
      width: inherit;
    }
  `};
`;

const MobileMenuLinkSmall = styled(MobileMenuLink)`
  & a {
    font-size: 18px;
    padding: 4px 0;
  }
`;

export const Stripe = styled.span`
  display: block;
  width: 26px;
  height: 2px;
  position: absolute;
  top: 50%;
  left: calc(50% - 13px);
  border-radius: 2px;
  transition: all .2s ease;
  background-color: ${(props) => props.theme.colors.black};
  
  &::before {
    content: '';
    display: block;
    width: 26px;
    height: 2px;
    border-radius: 2px;
    background-color: ${(props) => props.theme.colors.black};
    position: absolute;
    top: -6px;
    transition: transform .4s ease, top .4s ease;
  }

  &::after {
    content: '';
    display: block;
    width: 26px;
    height: 2px;
    border-radius: 2px;
    background-color: ${(props) => props.theme.colors.black};
    position: absolute;
    top: 6px;
    transition: transform .4s ease, top .4s ease;
  }
`;

export const HamburgerMenu = styled.button`
  width: 43px;
  height 43px;
  display: block;
  border-radius: 50%;
  border: 2px solid ${(props) => props.theme.colors.black};
  background-color: ${(props) => props.theme.colors.primary};
  position: absolute;
  right: 15px;
  top: 30px;
  z-index: 4;

  @media (min-width: ${(props) => props.theme.breakpoints.md}) {
    display: none;
  }

  ${(props) => props.open && css`
    align-self: flex-end;
    margin: 0;

    & > ${Stripe} {
      background-color: transparent;
    }

    & > ${Stripe}::before {
      transform: rotate(45deg);
      top: 0;
    }

    & > ${Stripe}::after {
      transform: rotate(-45deg);
      top: 0;
    }
  `};
`;

const MobileMenu = ({ showMenu }) => {
  const [activePage, setactivePage] = useState(null);
  const location = useLocation();

  useEffect(() => {
    setactivePage(location.pathname);
  }, [location]);

  return (
    <MobileMenuElement open={showMenu}>
      <MobileMenuLinkContainer>
        {menuItems.mainItems.map((link) => (
          <MobileMenuLink key={`${link.url}-mobile`} active={activePage === link.url}>
            <Link to={link.url}>
              { link.label }
            </Link>
          </MobileMenuLink>
        ))}
        {menuItems.subItems.map((link) => (
          <MobileMenuLinkSmall key={`${link.url}-mobile`} active={activePage === link.url}>
            <Link to={link.url}>{ link.label }</Link>
          </MobileMenuLinkSmall>
        ))}
      </MobileMenuLinkContainer>
    </MobileMenuElement>
  );
};

export default MobileMenu;
