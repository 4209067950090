import React from 'react';
import styled from 'styled-components';

const ListElement = styled.ul`
  list-style: none;
  padding: 0;
  ${(props) => (props.hasMargin ? `${props.theme.constants.defaultItems} 0` : '0')};
`;

const EmDashListItem = styled.li`
  font-size: 18px;
  padding: 1px 0;

  &::before {
    display: inline-block;
    content: '\\2014';
    padding-right: 8px;
  }
`;

const CheckedListItem = styled.li`
  font-size: 20px;
  line-height: 1.1;
  display: flex;
  align-items: flex-start;
  margin: 8px 0 !important;

  @media (min-width: ${(props) => props.theme.breakpoints.sm}) {
    font-size: 30px;
    align-items: center;
  }
`;

const CheckedBox = styled.div`
  margin: 4px 16px 0 0 !important;
  box-sizing: border-box;
  width: 16px;
  height: 16px;
  border: 2px solid ${(props) => props.theme.colors.black};

  @media (min-width: ${(props) => props.theme.breakpoints.sm}) {
    width: 20px;
    height: 20px;
  }
`;

const CheckMark = styled.div`
  display: inline-block;
  transform: rotate(45deg);
  height: 18px;
  width: 6px;
  margin: -10px 0px 13px 5px !important;
  border-bottom: 3px solid ${(props) => props.theme.colors.black};
  border-right: 3px solid ${(props) => props.theme.colors.black};

  @media (min-width: ${(props) => props.theme.breakpoints.sm}) {
    height: 22px;
    width: 10px;
    margin: -10px 0px 13px 5px !important;
    border-bottom: 4px solid ${(props) => props.theme.colors.black};
    border-right: 4px solid ${(props) => props.theme.colors.black};
  }
`;

const List = ({
  items,
  type,
}) => {
  const isCheckedList = type === 'Checkboxen';

  return (
    <ListElement hasMargin={!isCheckedList}>
      {items && !isCheckedList && items.map((item) => (
        <EmDashListItem key={item}>{ item }</EmDashListItem>
      ))}
      {items && isCheckedList && items.map((item) => (
        <CheckedListItem key={`${item}${Math.random()}`}>
          <CheckedBox><CheckMark /></CheckedBox>
          { item }
        </CheckedListItem>
      ))}
    </ListElement>
  );
};

export default List;
