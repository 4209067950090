import styled from 'styled-components';

const GlobalContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 100%;
`;

const ContentContainer = styled.div`
  width: 90%;
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-column-gap: 8px;
  padding: ${(props) => (props.sequence
    ? `0 0 ${props.theme.constants.defaultBlockMobile} 0`
    : `${props.theme.constants.defaultBlockMobile} 0`
  )};

  @media (min-width: ${(props) => props.theme.breakpoints.md}) {
    grid-column-gap: 32px;

    padding: ${(props) => (props.sequence
    ? `0 0 ${props.theme.constants.defaultBlock} 0`
    : `${props.theme.constants.defaultBlock} 0`
  )};
  }

  @media (min-width: ${(props) => props.theme.breakpoints.xl}) {
    width: 1200px;
  }
`;

const NavigationContainer = styled.div`
  width: 95%;

  @media (min-width: ${(props) => props.theme.breakpoints.lg}) {
    width: 93%;
  }

  @media (min-width: ${(props) => props.theme.breakpoints.xl}) {
    width: 90%;
  }
`;

export {
  GlobalContainer,
  ContentContainer,
  NavigationContainer,
};
