import React, { useState } from 'react';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { INLINES, MARKS, BLOCKS } from '@contentful/rich-text-types';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

const TopBar = styled.section`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  color: ${(props) => props.theme.colors.blue};
  cursor: pointer;

  & h4 {
    margin: 8px 0;
  }

  & h4:nth-of-type(2) {
    margin: 6px 0 10px 0;
  }
`;

const HarmonicaItemContainer = styled.article`
  border-top: 1px solid ${(props) => props.theme.colors.tertiary};
  width: 100%;

  &:last-of-type {
    border-bottom: 1px solid ${(props) => props.theme.colors.tertiary};
  }
`;

const Content = styled.section`
  padding: 16px 0;
`;

const Strikethrough = styled.u`
  text-decoration: line-through;
`;

const ParagraphNoMargin = styled.p`
  margin: 0;
`;

const options = {
  renderMark: {
    [MARKS.UNDERLINE]: (text) => <Strikethrough>{text}</Strikethrough>,
  },
  renderNode: {
    [INLINES.EMBEDDED_ENTRY]: (node) => {
      const { linkTekst, linkUrl } = node.data.target.fields;
      return <Link to={linkUrl}>{ linkTekst }</Link>;
    },
    [BLOCKS.EMBEDDED_ASSET]: (node) => {
      const { title, file } = node.data.target.fields;
      return <img alt={title} src={file.url} />;
    },
    [BLOCKS.PARAGRAPH]: (node, children) => <ParagraphNoMargin>{children}</ParagraphNoMargin>,
  },
  renderText: (text) => (
    text.split('\n').reduce((children, textSegment, index) => ([
      ...children,
      index > 0 && <br key={`${index}-linebreak`} />,
      textSegment,
    ]), [])
  ),
};

const HarmonicaItem = ({
  title,
  text,
}) => {
  const [open, toggleOpen] = useState(false);

  const handleClick = () => {
    toggleOpen(!open);
  };

  return (
    <HarmonicaItemContainer>
      <TopBar onClick={handleClick}>
        <h4>
          { title }
        </h4>
        <h4>{open ? '—' : '+'}</h4>
      </TopBar>
      {open
      && (
      <Content>
        {documentToReactComponents(text, options)}
      </Content>
      )}
    </HarmonicaItemContainer>
  );
};

export default HarmonicaItem;
