import React, { useState, useEffect } from 'react';
import {
  TextInputElement,
  DoubleInputRow,
  TextAreaElement,
  Form,
  SubmitButton,
  PrivacyStatement,
  ErrorMessage,
  SubmitSuccesContainer,
} from './styledElements';
import { ButtonElement } from '../Button';

const encode = (data) => (
  Object
    .keys(data)
    .map((key) => `${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`)
    .join('&')
);

// Remember to also change the fake form in index.html so netlify recieves the entries
const ContactForm = ({
  firstnamePlaceholder,
  surnamePlaceholder,
  emailPlaceholder,
  phonePlaceholder,
  remarksPlaceholder,
  errorMessage,
  succesMessage,
  disclaimer,
  buttonLabel,
}) => {
  const [firstname, setFirstname] = useState('');
  const [surname, setSurname] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [remarks, setRemarks] = useState('');
  const [loading, toggleLoading] = useState(false);
  const [successfulSubmission, setSuccessfulSubmission] = useState(false);
  const [failedSubmission, setFailedSubmission] = useState(false);
  const [requiredFieldsFilled, setRequiredFieldsFilled] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setFailedSubmission(false);

    if (!requiredFieldsFilled) {
      setFailedSubmission(true);
      return;
    }

    toggleLoading(true);

    try {
      await fetch(
        '/',
        {
          method: 'POST',
          headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
          body: encode({
            'form-name': 'contact',
            firstname,
            surname,
            email,
            phone,
            remarks,
          }),
        },
      );

      setSuccessfulSubmission(true);
      toggleLoading(false);
    } catch (error) {
      setFailedSubmission(true);
      toggleLoading(false);
    }
  };

  const resetForm = () => {
    setSuccessfulSubmission(false);
    setFirstname('');
    setSurname('');
    setEmail('');
    setPhone('');
    setRemarks('');
    toggleLoading(false);
  };

  useEffect(() => {
    if (
      firstname !== ''
      && surname !== ''
      && email !== ''
      && phone !== ''
    ) {
      setRequiredFieldsFilled(true);
    }
  }, [firstname, surname, email, phone]);

  return (
    <>
      {!successfulSubmission
        && (
        <Form onSubmit={handleSubmit}>
          <DoubleInputRow>
            <TextInputElement
              type="text"
              name="firstname"
              value={firstname}
              placeholder={firstnamePlaceholder}
              onChange={(e) => setFirstname(e.target.value)}
            />
            <TextInputElement
              type="text"
              name="surname"
              value={surname}
              placeholder={surnamePlaceholder}
              onChange={(e) => setSurname(e.target.value)}
            />
          </DoubleInputRow>
          <TextInputElement
            type="email"
            name="email"
            value={email}
            placeholder={emailPlaceholder}
            onChange={(e) => setEmail(e.target.value)}
          />
          <TextInputElement
            type="text"
            name="phone"
            value={phone}
            placeholder={phonePlaceholder}
            onChange={(e) => setPhone(e.target.value)}
          />
          <TextAreaElement
            name="remarks"
            rows="8"
            value={remarks}
            placeholder={remarksPlaceholder}
            onChange={(e) => setRemarks(e.target.value)}
          />
          <SubmitButton
            type="submit"
            disabled={loading}
          >
            {loading ? 'Bezig met versturen...' : buttonLabel}
          </SubmitButton>
          {failedSubmission && !requiredFieldsFilled
            && <ErrorMessage>Niet alles is ingevuld. Vul alsjeblieft alle velden in.</ErrorMessage>}

            {failedSubmission && requiredFieldsFilled
            && <ErrorMessage>{ errorMessage }</ErrorMessage>}
          <PrivacyStatement>{ disclaimer }</PrivacyStatement>
        </Form>
        )}
      {successfulSubmission
        && (
        <SubmitSuccesContainer>
          <p>{ succesMessage }</p>
          <ButtonElement
            type="button"
            onClick={resetForm}
          >
            Verstuur er nog één
          </ButtonElement>
        </SubmitSuccesContainer>
        )}
    </>
  );
};

export default ContactForm;
