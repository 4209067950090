import urlConstants from './urlConstants';

const menuItems = {
  mainItems: [
    {
      label: 'Wat we doen',
      url: urlConstants.WATWEDOEN,
    },
    {
      label: 'Wie we zijn',
      url: urlConstants.WIEWEZIJN,
    },
    {
      label: 'Resultaten',
      url: urlConstants.RESULTATEN,
    },
    {
      label: 'Youngleaders',
      url: urlConstants.YOUNGLEADERS,
    },
    {
      label: 'Vragen?',
      url: urlConstants.VRAGEN,
    },
    {
      label: 'Inschrijven',
      url: urlConstants.VOORLICHTING,
    },
  ],
  subItems: [
    {
      label: 'Data trainingsprogramma',
      url: urlConstants.DATA,
    },
    {
      label: 'Voor opvoeders',
      url: urlConstants.OPVOEDERS,
    },
    {
      label: 'Voor scholen',
      url: urlConstants.SCHOLEN,
    },
    {
      label: 'Voor volwassenen',
      url: urlConstants.VOLWASSENEN,
    },
    {
      label: 'Gedragscode',
      url: urlConstants.GEDRAGSCODE,
    },
    {
      label: 'Partners',
      url: urlConstants.PARTNERS,
    },
    {
      label: 'Contact',
      url: urlConstants.CONTACT,
    },
  ],
};

export default menuItems;
