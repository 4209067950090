import React from 'react';
import styled from 'styled-components';
import HarmonicaItem from './HarmonicaItem';

const HarmonicaContainer = styled.div`
  width: 100%;
  margin-top: ${(props) => (props.sequence ? props.theme.constants.defaultContent : '0')};
`;

const Harmonica = ({ items, sequence }) => (
  <HarmonicaContainer sequence={sequence}>
    {items && items.map((item) => (
      <HarmonicaItem
        key={item.sys.id + Math.random()}
        title={item.fields.vraag}
        text={item.fields.antwoord}
      />
    ))}
  </HarmonicaContainer>
);

export default Harmonica;
