import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import {
  GlobalContainer,
  ContentContainer,
} from './Container';
import urlConstants from '../constants/urlConstants';
import menuItems from '../constants/menuItems';
import footerImage from '../assets/photos/footer-background.jpg';

const PageContainer = styled(GlobalContainer)`
  background: url(${footerImage}) ${(props) => props.theme.colors.black};
  background-size: cover;
  color: white;
`;

const FooterContainer = styled(ContentContainer)`
  padding: ${(props) => props.theme.constants.defaultItemsSmall} 0 0;

  @media (min-width: ${(props) => props.theme.breakpoints.sm}) {
    padding: ${(props) => props.theme.constants.defaultContent} 0 0;
  };
`;

const LinkContainer = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
  grid-area: 1 / 1 / 2 / 13; 

  @media (min-width: ${(props) => props.theme.breakpoints.sm}) {
    // half om half
    grid-area: 1 / 1 / 2 / 7;
  }

  @media (min-width: ${(props) => props.theme.breakpoints.md}) {
    // 4 om 12
    grid-area: 1 / 1 / 2 / 5;
  }

  &:nth-of-type(2) {
    grid-area: 2 / 1 / 3 / 13;

    @media (min-width: ${(props) => props.theme.breakpoints.sm}) {
      // half om half
      grid-area: 1 / 7 / 2 / 13;
    }

    @media (min-width: ${(props) => props.theme.breakpoints.md}) {
      // 8 om 12
      grid-area: 1 / 5 / 2 / 13;
    }

  }
`;

const Ending = styled.ul`
  grid-area: 3 / 1 / 4 / 13;
  list-style: none;
  margin: ${(props) => props.theme.constants.defaultContent} 0 0;
  padding: 20px 0;
  display: flex;
  flex-direction: column;
  border-top: 1px solid ${(props) => props.theme.colors.tertiary};

  @media (min-width: ${(props) => props.theme.breakpoints.sm}) {
    grid-area: 2 / 1 / 3 / 13;
    flex-direction: row;
    flex-wrap: wrap;
  }
`;

const EndingLink = styled.li`
  font-size: 14px;
  padding: 0 8px;
  color: ${(props) => props.theme.colors.tertiary};

  &::after {
    @media (min-width: ${(props) => props.theme.breakpoints.md}) {
      padding: 0 16px;
      display: inline-block;
      content: '|';
    }
  }

  &:last-of-type::after {
    content: '';
    padding: 0;
  }

  & a,
  a:visited {
    font-size: 14px;
    margin: 0 8px;
    color: ${(props) => props.theme.colors.tertiary};
  }

  & a::before {
    height: 1px;
  }
  
  & a::after {
    display: none;
  }

`;

const FooterLink = styled.li`
  & a, 
  a:visited {
    color: ${(props) => props.theme.colors.primary};
    text-decoration: none;
    font-size: 30px;
    font-weight: 400;
  }

  & a::before {
    margin-bottom: 0;
  }
`;

const SmallFooterLink = styled(FooterLink)`
  & a {
    font-size: 18px;
    padding: 4px 0;
  }

  & a::before {
    height: 1px;
    margin-bottom: 4px;
  }
`;

const Footer = () => (
  <PageContainer>
    <FooterContainer>
      <LinkContainer>
        {menuItems.mainItems.map((link) => (
          <FooterLink key={`footer-${link.url}`}>
            <Link to={link.url}>{link.label}</Link>
          </FooterLink>
        ))}
      </LinkContainer>
      <LinkContainer>
        {menuItems.subItems.map((link) => (
          <SmallFooterLink key={`footer-${link.url}`}>
            <Link to={link.url}>{link.label}</Link>
          </SmallFooterLink>
        ))}
      </LinkContainer>
      <Ending>
        <EndingLink>&#169; Inside Out 2020</EndingLink>
        <EndingLink>
          <Link to={urlConstants.PRIVACY}>
            Privacy Policy
          </Link>
        </EndingLink>
        <EndingLink>
          <Link to={urlConstants.VOORWAARDEN}>
            Algemene Voorwaarden
          </Link>
        </EndingLink>
        <EndingLink>Webdesign by MarsMedia &amp; Nova Web Development</EndingLink>
      </Ending>
    </FooterContainer>
  </PageContainer>
);

export default Footer;
