import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import Navigation from '../components/Navigation';
import {
  ContentContainer,
  GlobalContainer,
  NavigationContainer,
} from '../components/Container';
import arrow from '../assets/svgs/arrow-yellow.svg';
import headerDefaultImage from '../assets/photos/header-background.jpg';

const Header = styled.article`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  @media (min-width: ${(props) => props.theme.breakpoints.md}) {
    min-height: 700px;
  }
`;

const HeaderContent = styled.section`
  grid-area: 1 / 1 / 2 / 13;
  width: 100%;
  height: 320px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  
  & a, a:visited {
    color: ${(props) => props.theme.colors.primary};
    position: relative;
  }

  & a::after {
    display: inline-block;
    content: url(${arrow});
    padding-left: 8px;
    position: absolute;
    top: 3px;
    width: 18px;
    height: 18px;
  }

  @media (min-width: ${(props) => props.theme.breakpoints.sm}) {
    height: 320px;
  }

  @media (min-width: ${(props) => props.theme.breakpoints.lg}) {
    grid-area: 1 / 1 / 2 / 9;
  }

`;

const DefaultHeaderContent = styled(HeaderContent)`
  @media (min-width: ${(props) => props.theme.breakpoints.lg}) {
    grid-area: 1 / 1 / 2 / 10;
  }
`;

const HeaderParagrahp = styled.p`
  font-size: 26px;
  margin: 16px 0;
  line-height: 1.1;
  color: ${(props) => props.theme.colors.white};
  
  @media (min-width: ${(props) => props.theme.breakpoints.sm}) {
    font-size: 30px;
  }
`;

const LinkContainer = styled.span`
  width: auto;
`;

const HeaderContainer = styled(GlobalContainer)`
  background: url(${(props) => (!props.backgroundUrl ? headerDefaultImage : props.backgroundUrl)});
  background-size: cover;
`;

const HeaderLayout = ({
  title,
  subTitle,
  linkLabel,
  linkUrl,
  backgroundUrl,
}) => (
  <HeaderContainer backgroundUrl={backgroundUrl}>
    <Header>
      <NavigationContainer>
        <Navigation />
      </NavigationContainer>
      <ContentContainer>
        <DefaultHeaderContent>
          {title && <h1>{ title }</h1>}
          {subTitle && <HeaderParagrahp>{ subTitle }</HeaderParagrahp>}
          {(linkLabel && linkUrl)
                && (
                  <LinkContainer>
                    <Link to={linkUrl}>
                      { linkLabel }
                    </Link>
                  </LinkContainer>
                )}
        </DefaultHeaderContent>
      </ContentContainer>
    </Header>
  </HeaderContainer>
);

export default HeaderLayout;
