import React from 'react';
import styled, { css } from 'styled-components';
import EmDashTitle from './EmDashTitle';

const ImageElement = styled.img`
  display: block;
  width: 100%;
`;

const OverlayContent = styled.section`
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  box-sizing: border-box;
  height: 100%;
  width: 100%;
  bottom: 0;
  left: 0;
  padding: 28px;

  ${(props) => (props.background
    ? css`
    background-color: ${props.theme.colors.primary};
    `
    : css`
    background: linear-gradient(180deg, rgba(255,255,255,0) 0%, rgba(94,94,94,0) 38%, rgba(0,0,0,0.8939950980392157) 87%);
    `
  )};
`;

const OverlayText = styled.p`
  color: ${(props) => (props.dark ? props.theme.colors.black : props.theme.colors.primary)};
  font-weight: 600;
  font-size: 20px;
  line-height: 1.1;
  margin: 8px 0 0;

  @media (min-width: ${(props) => props.theme.breakpoints.sm}) {
    font-size: 24px;
  }
`;

const ImageOverlay = ({
  image,
  author,
  age,
  text,
}) => {
  const title = age !== undefined ? `${author} ${age} jaar` : author;

  return (
    <>
      <OverlayContent
        background={!image}
      >
        <EmDashTitle
          dark={!image}
          title={title}
        />
        {text && (
          <OverlayText
            dark={!image}
          >
            {`“${text}”`}
          </OverlayText>
        )}
      </OverlayContent>
      {image
        && (
          <ImageElement
            src={image}
          />
        )}
    </>
  );
};

export default ImageOverlay;
