import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import styled, { css } from 'styled-components';
import urlConstants from '../constants/urlConstants';
import Button from './Button';
import logoWhite from '../assets/svgs/logo-white.svg';
import logoBlack from '../assets/svgs/logo-black.svg';
import menuItems from '../constants/menuItems';
import MobileMenu, { HamburgerMenu, Stripe } from './MobileMenu';

const NavigationBar = styled.nav`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 120px;
  position: relative;

  & > a::before {
    display: none;
  }
`;

const LinkContainer = styled.ul`
  display: none;
  padding: 0;

  @media (min-width: ${(props) => props.theme.breakpoints.md}) {
    display: flex;
    justify-content: space-between;
    align-items: center;
    list-style: none;
    min-width: 585px;
    width: 50%;
  }
`;

const MenuLink = styled.li`
  & a, 
  a:visited {
    color: ${(props) => (props.inverted ? props.theme.colors.secondary : props.theme.colors.white)};
    font-size: 18px;
    font-weight: 600;
  }

  ${(props) => props.active && css`
    & a::before {
      display: block;
      margin-left: 0;
      width: 100%;
    }
  `};

`;

const Logo = styled.img`
  width: 120px;

  @media (min-width: ${(props) => props.theme.breakpoints.lg}) {
    width: 160px;
  }
`;

const Navigation = ({ inverted }) => {
  const [activePage, setactivePage] = useState(null);
  const [mobileMenu, toggleMobileMenu] = useState(false);
  const location = useLocation();

  useEffect(() => {
    setactivePage(location.pathname);
  }, [location]);

  return (
    <>
      <MobileMenu showMenu={mobileMenu} />
      <HamburgerMenu
        open={mobileMenu}
        onClick={() => toggleMobileMenu(!mobileMenu)}
      >
        <Stripe />
      </HamburgerMenu>
      <NavigationBar>
        <Link to="/">
          <Logo alt="logo" src={inverted ? logoBlack : logoWhite} />
        </Link>
        <LinkContainer>
          {menuItems.mainItems.map((link) => (
            link.url !== urlConstants.VOORLICHTING
              ? (
                <MenuLink key={`main-${link.url}`} active={activePage === link.url} inverted={inverted}>
                  <Link to={link.url}>{ link.label }</Link>
                </MenuLink>
              )
              : (
                <MenuLink key={`main-${link.url}`} active={activePage === link.url}>
                  <Button
                    label={link.label}
                    linkTo={link.url}
                  />
                </MenuLink>
              )
          ))}
        </LinkContainer>
      </NavigationBar>
    </>
  );
};

export default Navigation;
