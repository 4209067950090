import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import Header from '../layouts/Header';
import Info from '../layouts/Info';
import urlConstants from '../constants/urlConstants';
import Testimonial from '../layouts/Testimonial';
import ParagraphBig from '../components/ParagraphBig';
import ActionBar from '../components/ActionBar';
import {
  GlobalContainer,
  ContentContainer,
} from '../components/Container';
import VideoImageGrid from '../layouts/VideoImageGrid';
import Footer from '../components/Footer';
import Introduction from '../layouts/Introduction';
import headerHomeImage from '../assets/photos/header-home-background.jpg';
import { client } from '../client';

const TopTextSection = styled.div`
  grid-area: 1 / 1 / 2 / 12;
  margin-bottom: ${(props) => props.theme.constants.defaultContentMobile};

  @media (min-width: ${(props) => props.theme.breakpoints.sm}) {
    margin-bottom: ${(props) => props.theme.constants.defaultContent};
  }
`;

const BottomTextSection = styled.div`
  margin-top: ${(props) => props.theme.constants.defaultContentMobile};
  grid-area: 3 / 1 / 4 / 13;

  @media (min-width: ${(props) => props.theme.breakpoints.sm}) {
    margin-top: ${(props) => props.theme.constants.defaultContent};
  }
`;

const HomePage = () => {
  const [content, setContent] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      const result = await client.getEntries({
        content_type: 'homepage',
        include: 10,
      });
      setContent(result.items[0].fields);
    };
    fetchData();
  }, []);

  return (
    <>
      {content
        && (
        <>
          <Header
            title={content.header.fields.hoofdtitel}
            subTitle={content.header.fields.subtitel}
            linkLabel={content.header.fields.link.fields.linkTekst}
            linkUrl={content.header.fields.link.fields.linkUrl}
            backgroundUrl={headerHomeImage}
          />
          <Introduction
            title={content.introductie.fields.titel}
            text={content.introductie.fields.introductie}
          />
          <GlobalContainer>
            <ContentContainer sequence>
              <TopTextSection>
                <h2>{ content.infoKoptekst }</h2>
                <ParagraphBig noMarginBottom>
                  { content.infoSubtekst }
                </ParagraphBig>
              </TopTextSection>
              <VideoImageGrid
                items={content.infoMediaGrid}
              />
              <BottomTextSection>
                <ActionBar
                  buttonlLabel={content.infoButton.fields.linkTekst}
                  ButtonLinkTo={urlConstants.REVIEWS}
                  anchorLabel={content.infoLink.fields.linkTekst}
                  anchorLinkTo={urlConstants.WATWEDOEN}
                />
              </BottomTextSection>
            </ContentContainer>
          </GlobalContainer>
          <Testimonial
            testimonial={content.testimonialBlok.fields.testimonial}
            author={content.testimonialBlok.fields.naam}
            age={content.testimonialBlok.fields.leeftijd}
            buttonLabel={content.testimonialButton.fields.linkTekst}
            buttonUrl={content.testimonialButton.fields.linkUrl}
          />
          <Info title={content.voorlichting.fields.titelLinks}>
            <h2>{content.voorlichting.fields.koptekst1}</h2>
            {content.voorlichting.fields.koptekst2
              && <h2>{content.voorlichting.fields.koptekst2}</h2>}
            <ParagraphBig>
              { content.voorlichting.fields.paragraaf }
            </ParagraphBig>
            <ActionBar
              buttonlLabel={content.voorlichting.fields.button
                && content.voorlichting.fields.button.fields.linkTekst}
              buttonLinkTo={content.voorlichting.fields.button
                && content.voorlichting.fields.button.fields.linkUrl}
              anchorLabel={content.voorlichting.fields.link
                && content.voorlichting.fields.link.fields.linkTekst}
              anchorLinkTo={content.voorlichting.fields.link
                && content.voorlichting.fields.link.fields.linkUrl}
            />
          </Info>
          <Footer />
        </>
        )}
    </>
  );
};

export default HomePage;
