import React from 'react';
import {
  BrowserRouter as Router,
  Route,
  Switch,
} from 'react-router-dom';
import urlConstants from './constants/urlConstants';
import ScrollToTop from './components/ScrollToTop';
import {
  HomePage,
  WatWeDoenPage,
  WieWeZijnPage,
  ReviewsPage,
  VragenPage,
  VoorOpvoedersPage,
  VoorScholenPage,
  GedragscodePage,
  ContactPage,
  InschrijvenVoorlichtingPage,
  InschrijvenTrainingPage,
  DataPage,
  ShopPage,
  PrivacyStatementPage,
  PartnersPage,
  AlgemeneVoorwaardenPage,
  VoorVolwassenenPage,
} from './pages';

function App() {
  return (
    <Router>
      <>
        <ScrollToTop />
        <Switch>
          <Route exact path={urlConstants.HOME}>
            <HomePage />
          </Route>
          <Route path={urlConstants.WATWEDOEN}>
            <WatWeDoenPage />
          </Route>
          <Route path={urlConstants.WIEWEZIJN}>
            <WieWeZijnPage />
          </Route>
          <Route path={urlConstants.RESULTATEN}>
            <ReviewsPage />
          </Route>
          <Route path={urlConstants.VRAGEN}>
            <VragenPage />
          </Route>
          <Route exact path={urlConstants.OPVOEDERS}>
            <VoorOpvoedersPage />
          </Route>
          <Route exact path={urlConstants.SCHOLEN}>
            <VoorScholenPage />
          </Route>
          <Route exact path={urlConstants.VOLWASSENEN}>
            <VoorVolwassenenPage />
          </Route>
          <Route path={urlConstants.GEDRAGSCODE}>
            <GedragscodePage />
          </Route>
          <Route path={urlConstants.CONTACT}>
            <ContactPage />
          </Route>
          <Route exact path={urlConstants.VOORLICHTING}>
            <InschrijvenVoorlichtingPage />
          </Route>
          <Route exact path={urlConstants.TRAINING}>
            <InschrijvenTrainingPage />
          </Route>
          <Route exact path={urlConstants.DATA}>
            <DataPage />
          </Route>
          <Route path={urlConstants.SHOP}>
            <ShopPage />
          </Route>
          <Route path={urlConstants.PARTNERS}>
            <PartnersPage />
          </Route>
          <Route path={urlConstants.PRIVACY}>
            <PrivacyStatementPage />
          </Route>
          <Route path={urlConstants.VOORWAARDEN}>
            <AlgemeneVoorwaardenPage />
          </Route>
        </Switch>
      </>
    </Router>
  );
}

export default App;
