import React, { useEffect, useState } from 'react';
import Header from '../layouts/Header';
import Footer from '../components/Footer';
import Introduction from '../layouts/Introduction';
import HighLight from '../layouts/Highlight';
import ParagraphBig from '../components/ParagraphBig';
import ActionBar from '../components/ActionBar';
import Info from '../layouts/Info';
import { client } from '../client';

const VoorVolwassenenPage = () => {
  const [content, setContent] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      const result = await client.getEntries({
        content_type: 'voorVolwassenenPage',
        include: 10,
      });
      setContent(result.items[0].fields);
    };
    fetchData();
  }, []);

  return (
    <>
      { content
          && (
            <>
              <Header
                title={content.header.fields.hoofdtitel}
                subTitle={content.header.fields.subtitel && content.header.fields.subtitel}
              />
              <Introduction
                title={content.introductieBlok.fields.titel}
                text={content.introductieBlok.fields.introductie}
              />
              <HighLight
                title={content.highlightBlokTitel}
                items={content.highlightBlokArtikelen}
              />
              <Introduction
                title={content.informatieBlok.fields.titel}
                text={content.informatieBlok.fields.introductie}
              />
              {content.voorlichting && (
                <Info title={content.voorlichting.fields.titelLinks}>
                  <h2>{content.voorlichting.fields.koptekst1}</h2>
                  {content.voorlichting.fields.koptekst2
                      && <h2>{content.voorlichting.fields.koptekst2}</h2>}
                  <ParagraphBig>
                    { content.voorlichting.fields.paragraaf }
                  </ParagraphBig>
                  <ActionBar
                    buttonlLabel={content.voorlichting.fields.button
                      && content.voorlichting.fields.button.fields.linkTekst}
                    buttonLinkTo={content.voorlichting.fields.button
                      && content.voorlichting.fields.button.fields.linkUrl}
                    anchorLabel={content.voorlichting.fields.link
                      && content.voorlichting.fields.link.fields.linkTekst}
                    anchorLinkTo={content.voorlichting.fields.link
                      && content.voorlichting.fields.link.fields.linkUrl}
                  />
                </Info>
              )}
              <Footer />
            </>
          )}
    </>
  );
};

export default VoorVolwassenenPage;
