import React from 'react';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { INLINES, MARKS, BLOCKS } from '@contentful/rich-text-types';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import Info from './Info';
import List from '../components/List';
import Harmonica from '../components/Harmonica';

const TextWrapper = styled.article`
  & *:first-child {
    margin-top: 0;
  }
`;

const Image = styled.img`
  width: 100%;
`;

const ParagraphNoMargin = styled.p`
  margin: 0;
`;

const Highlight = styled.span`
  position: relative;

  &::after {
    display: inline-block;
    content: '';
    position: absolute;
    top: 11px;
    left: -8px;
    z-index: -1;
    width: 100%;
    height: 10px;
    padding: 8px;
    border-radius: 19px 6px 11px 7px;
    background-color: ${(props) => props.theme.colors.primaryLight};
    transform: rotate(-2deg);
  }

  @media (min-width: ${(props) => props.theme.breakpoints.sm}) {
    &::after {
      height: 20px;
    }
  };
`;

const options = {
  renderMark: {
    [MARKS.UNDERLINE]: (text) => <Highlight>{text}</Highlight>,
  },
  renderNode: {
    [INLINES.EMBEDDED_ENTRY]: (node) => {
      const { linkTekst, linkUrl } = node.data.target.fields;
      return <Link to={linkUrl}>{ linkTekst }</Link>;
    },
    [BLOCKS.EMBEDDED_ENTRY]: (node) => {
      const { type, lijstItems, vragen } = node.data.target.fields;

      if (vragen) {
        return <Harmonica sequence items={vragen} />;
      }
      if (lijstItems) {
        return <List type={type} items={lijstItems} />;
      }

      return <></>;
    },
    [BLOCKS.EMBEDDED_ASSET]: (node) => {
      const { title, file } = node.data.target.fields;
      return <Image alt={title} src={file.url} />;
    },
    [BLOCKS.PARAGRAPH]: (node, children) => <ParagraphNoMargin>{children}</ParagraphNoMargin>,
  },
  renderText: (text) => (
    text.split('\n').reduce((children, textSegment, index) => ([
      ...children,
      index > 0 && <br key={`${index}-linebreak`} />,
      textSegment,
    ]), [])
  ),
};

const IntroductionLayout = ({
  title,
  text,
}) => (
  <Info title={title}>
    <TextWrapper>
      {documentToReactComponents(text, options)}
    </TextWrapper>
  </Info>
);

export default IntroductionLayout;
