import React from 'react';
import styled from 'styled-components';
import EmDashTitle from '../components/EmDashTitle';
import {
  ContentContainer,
  GlobalContainer,
} from '../components/Container';

const TitleSection = styled.section`
  grid-area: 1 / 1 / 2 / 13;
  margin: 0 0 21px 0;

  @media (min-width: ${(props) => props.theme.breakpoints.md}) {
    grid-area: 1 / 1 / 2 / 9;
  }
`;

const ContentSection = styled.section`
  grid-area: 2 / 1 / 2 / 13;

  @media (min-width: ${(props) => props.theme.breakpoints.md}) {
    grid-area: 1 / 4 / 2 / 12;
  }
`;

const InfoLayout = ({
  title,
  children,
  sequence,
}) => (
  <GlobalContainer>
    <ContentContainer sequence={sequence === undefined ? false : sequence}>
      <TitleSection>
        <EmDashTitle
          after
          dark
          title={title}
        />
      </TitleSection>
      <ContentSection>
        { children }
      </ContentSection>
    </ContentContainer>
  </GlobalContainer>
);

export default InfoLayout;
