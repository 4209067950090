import React from 'react';
import styled from 'styled-components';
import {
  GlobalContainer,
  ContentContainer,
} from '../components/Container';
import {
  ThreeColumnGridContainer,
  Column,
} from './ThreeColumnGrid';
import TextImage from '../components/TextImage';

const YellowContainer = styled(ContentContainer)`
  background-color: ${(props) => props.theme.colors.primary};
  padding: ${(props) => props.theme.constants.defaultBlockMobile} 20px;

  h2 {
    line-height: 0.9;
    margin: 0 0 ${(props) => props.theme.constants.defaultItems} 0;
  }

  @media (min-width: ${(props) => props.theme.breakpoints.sm}) {
    padding: ${(props) => props.theme.constants.defaultBlock} ${(props) => props.theme.constants.defaultContent};
  }
`;

const TextSection = styled.section`
  grid-area: 1 / 1 / 2 / 13;

  @media (min-width: ${(props) => props.theme.breakpoints.sm}) {
    grid-area: 1 / 1 / 2 / 9;
  }
`;

const ColumnSection = styled.section`
  grid-area: 2 / 1 / 3 / 13;
`;

const TextItem = styled.p`
  margin: 0;
`;

const HighlightLayout = ({
  title,
  textOnly,
  items,
}) => (
  <GlobalContainer>
    <YellowContainer>
      <TextSection>
        <h2>{ title }</h2>
      </TextSection>
      <ColumnSection>
        {!textOnly && items && (
          <ThreeColumnGridContainer>
            {items.map((item) => (
              <Column key={item.sys.id}>
                <TextImage
                  image={item.fields.afbeelding.fields.file.url}
                  title={item.fields.koptekst}
                  text={item.fields.paragraaf}
                  linkLabel={item.fields.link && item.fields.link.fields.linkTekst}
                  linkUrl={item.fields.link && item.fields.link.fields.link}
                />
              </Column>
            ))}
          </ThreeColumnGridContainer>
        )}
        {textOnly && (
          <ThreeColumnGridContainer>
            {items && items.map((item) => (
              // DIT MOET NOG AANGEPAST
              <Column key={Math.random()}>
                <TextItem>{`“${item.fields.testimonial}”`}</TextItem>
                <TextItem>{`— ${item.fields.naam} ${item.fields.leeftijd} jaar`}</TextItem>
              </Column>
            ))}
          </ThreeColumnGridContainer>
        )}
      </ColumnSection>
    </YellowContainer>
  </GlobalContainer>
);

export default HighlightLayout;
