import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import EmDashTitle from './EmDashTitle';
import ActionButton from './ActionButton';

const ImageElement = styled.img`
  display: block;
  width: 100%;
  
  transition: all 0.3s ease-out;
  transform: scale(1);
`;

const VideoOverlayContainer = styled.div`
  width: 100%;
  height: 100%;

  &:hover > ${ImageElement} {
    transform: scale(1.05);
  }
`;

const OverlayContent = styled.section`
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  box-sizing: border-box;
  height: 100%;
  width: 100%;
  bottom: 0;
  left: 0;
  padding: 28px;
  z-index: 2;

  ${(props) => (props.background
    ? css`
      background-color: ${props.theme.colors.primary};
    `
    : css`
      background: linear-gradient(180deg, rgba(255,255,255,0) 0%, rgba(94,94,94,0) 66%, rgba(0,0,0,0.8939950980392157) 100%);
    `
  )};
`;

const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  z-index: 10;
  position: absolute;
  box-sizing: border-box;
  bottom: 0;
  left: 0;
`;

const ResponsiveVideoContainer = styled.div`
  position: relative;

  &::before {
    content: "";
    display: block;
    padding-bottom: calc(100% / (16/9));
  }

  & > :first-child {
    position: absolute;
    width: 100%;
    top: 0;
    left: 0;
    height: 100%;
  }
  
  & > img {
    height: auto;
  }

`;

const VideoOverlay = ({
  image,
  author,
  age,
  videoId,
}) => {
  const [viewVideo, toggleViewVideo] = useState(false);
  const title = age !== undefined ? `${author} ${age} jaar` : author;

  const playVideo = () => {
    toggleViewVideo(true);
  };

  return (
    <VideoOverlayContainer>
      {videoId && !viewVideo
        && (
          <>
            <ButtonContainer>
              <ActionButton clickHandler={playVideo} />
            </ButtonContainer>
            <OverlayContent background={!image}>
              <EmDashTitle
                title={title}
              />
            </OverlayContent>
          </>
        )}

      {videoId && viewVideo
          && (
            <ResponsiveVideoContainer>
              <iframe
                title="unique title"
                src={`https://player.vimeo.com/video/${videoId}?autoplay=1&title=0&byline=0&portrait=0`}
                width="1600"
                height="900"
                frameBorder="0"
                allow="autoplay; fullscreen"
                allowFullScreen
              />
            </ResponsiveVideoContainer>
          )}
      {image && !viewVideo
        && (
          <ImageElement
            src={image}
          />
        )}
    </VideoOverlayContainer>
  );
};

export default VideoOverlay;
