import styled from 'styled-components';

export const ThreeColumnGridContainer = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin: 10px 0 ${(props) => props.theme.constants.defaultItemsMobile} 0;

  &:last-of-type {
    margin: 10px 0 0 0;
  }

  @media (min-width: ${(props) => props.theme.breakpoints.sm}) {
    margin: 10px 0 ${(props) => props.theme.constants.defaultItems} 0;
  }
`;

export const Column = styled.div`
  width: 100%;
  margin: 20px 0;
  min-width: 270px;
  position: relative;

  @media (min-width: ${(props) => props.theme.breakpoints.sm}) {
    margin: 20px 0;
  }

  @media (min-width: ${(props) => props.theme.breakpoints.md}) {
    width: 48%;
  }

  @media (min-width: ${(props) => props.theme.breakpoints.lg}) {
    width: 31%;
  }
`;

export default null;
