import styled from 'styled-components';

export const TwoColumnGrid = styled.div`
  grid-area: 1 / 1 / 2 / 13;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin: 10px 0 ${(props) => props.theme.constants.defaultItems} 0;

  &:last-of-type {
    margin: 10px 0 0 0;
  }
`;

export const Column = styled.div`
  width: 100%;
  margin: 20px 0;
  min-width: 270px;
  position: relative;

  @media (min-width: ${(props) => props.theme.breakpoints.md}) {
    width: 47%;
  }
`;

export default null;
