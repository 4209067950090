import React from 'react';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

const Image = styled.img`
  width: 100%;
`;

const ImageHolder = styled.div`
  height: 210px;
  width: 100%;
  overflow: hidden;
  background-color: #ededed;
  position: relative;
  overflow: hidden;
`;

const Title = styled.h4`
  margin: 20px 0 ${(props) => (props.margin ? '0' : '12px')} 0;
`;

const SubText = styled.p`
  margin: 0;
`;

const TextImage = ({
  image,
  title,
  text,
  subText,
  linkLabel,
  linkUrl,
}) => (
  <>
    <ImageHolder>
      <Image alt="image" src={image} />
    </ImageHolder>
    <Title margin={subText !== undefined}>{ title }</Title>
    {subText && <SubText>{ subText }</SubText>}
    {documentToReactComponents(text)}
    {linkLabel && linkUrl && (
      <h6>
        <Link to={linkUrl}>{ linkLabel }</Link>
      </h6>
    )}
  </>
);

export default TextImage;
