import React, { useEffect, useState } from 'react';
import Header from '../layouts/Header';
import Info from '../layouts/Info';
import ParagraphBig from '../components/ParagraphBig';
import ActionBar from '../components/ActionBar';
import Footer from '../components/Footer';
import Harmonica from '../components/Harmonica';
import { client } from '../client';

const DataPage = () => {
  const [content, setContent] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      const result = await client.getEntries({
        content_type: 'dataPage',
        include: 10,
      });
      setContent(result.items[0].fields);
    };
    fetchData();
  }, []);

  return (
    <>
      {content
        && (
          <>
            <Header
              title={content.header.fields.hoofdtitel}
              subTitle={content.header.fields.subtitel && content.header.fields.subtitel}
            />
            <Info
              title={content.voorlichtingen.fields.titel}
            >
              <Harmonica items={content.voorlichtingen.fields.vragen} />
            </Info>
            <Info
              title={content.trainingen.fields.titel}
              sequence
            >
              <Harmonica items={content.trainingen.fields.vragen} />
            </Info>
            {content.vragenblok1 && (
              <Info
                title={content.vragenBlok1.fields.titel}
                sequence
              >
                <Harmonica items={content.vragenBlok1.fields.vragen} />
              </Info>
            )}
            {content.vragenblok2 && (
              <Info
                title={content.vragenblok2.fields.titel}
                sequence
              >
                <Harmonica items={content.vragenblok2.fields.vragen} />
              </Info>
            )}
            <Info sequence title={content.voorlichting.fields.titelLinks}>
              <h2>{content.voorlichting.fields.koptekst1}</h2>
              {content.voorlichting.fields.koptekst2
                && <h2>{content.voorlichting.fields.koptekst2}</h2>}
              <ParagraphBig>
                { content.voorlichting.fields.paragraaf }
              </ParagraphBig>
              <ActionBar
                buttonlLabel={content.voorlichting.fields.button
                  && content.voorlichting.fields.button.fields.linkTekst}
                buttonLinkTo={content.voorlichting.fields.button
                  && content.voorlichting.fields.button.fields.linkUrl}
                anchorLabel={content.voorlichting.fields.link
                  && content.voorlichting.fields.link.fields.linkTekst}
                anchorLinkTo={content.voorlichting.fields.link
                  && content.voorlichting.fields.link.fields.linkUrl}
              />
            </Info>
            <Footer />
          </>
        )}
    </>
  );
};

export default DataPage;
