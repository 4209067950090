import { createGlobalStyle } from 'styled-components';
import theme from './theme';
import arrow from '../assets/svgs/arrow-pink.svg';

const GlobalStyle = createGlobalStyle`
    body {
        margin: 0;
        color: ${theme.colors.black};
        font-family: acumin-pro, Helvetica, sans-serif;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale; 
    }

    h1 {
        color: ${theme.colors.primary};
        font-weight: 600;
        font-size: 38px;
        line-height: 0.9;
        margin: 0;
    }

    h2 {
        font-weight: 600;
        font-size: 36px;
        line-height: 1.1;
        margin: 0;
    }

    /* also known as intro */
    h3 {    
        font-weight: 500;
        font-size: 26px;
        line-height: 1;
        margin: 0;
    }

    h4 {
        font-weight: 600;
        font-size: 20px;
    }

    /* paragraphBig */
    h5 {
        font-weight: 300;
        font-size: 24px;
        line-height: 1;
        margin: 12px 0;
    }

    h6 {
        font-weight: 400;
        font-size: 16px;
        margin: 16px 0;
    }

    a h4 {
        color: ${theme.colors.blue};
    }

    a:visited h4 {
        color: ${theme.colors.blue};
    }

    a, 
    a:visited {
        font-size: 18px;    
        color: ${theme.colors.secondary};
        text-decoration: none;
        font-weight: 500;
        position: relative;
    }

    & a::before {
        content: '';
        display: inline-block;
        position: absolute;
        bottom: 0;
        transition: all .25s ease-in-out;
        background-color: currentColor;
        height: 2px;
        margin-bottom: -0.25rem;
        width: 0;
    }

    & a:hover::before {
        width: 100%;
    }

    h6 > a::after {
        display: inline-block;
        content: url(${arrow});
        padding-left: 8px;
        position: absolute;
        bottom: 3px;
        width: 18px;
        height: 18px;
    }

    a:visited {  
        color: ${theme.colors.secondary};
    }

    p {
        font-weight: 400;
        font-size: 16px;
    }

    @media (min-width: ${theme.breakpoints.sm}) {
        h1 {
            font-size: 70px;
        }
    
        h2 {
            font-size: 60px;
        }
    
        h3 {    
            font-size: 40px;
        }
    
        h4 {
            font-size: 22px;
        }

        h5 {
            font-size: 30px;
        }

        h6 {
            font-size: 18px;
        }
        
        p {
            font-size: 18px;
        }
    }
`;

export default GlobalStyle;
