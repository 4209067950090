import React from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

export const ButtonElement = styled.button`
  display: block;
  background-color: ${(props) => props.theme.colors.primary};
  border: 2px solid ${(props) => props.theme.colors.black};
  font-weight: 600;
  font-size: 18px;
  padding: 8px 32px;
  border-bottom: 6px solid;
  cursor: pointer;
  outline: none;

  &:hover {
    border-bottom: 2px solid;
    transform: translateY(1px);
  }
`;

const Button = ({ label, linkTo }) => {
  const history = useHistory();

  const handleClick = () => {
    if (linkTo !== undefined) {
      history.push(linkTo);
    }
  };

  return (
    <ButtonElement
      type="button"
      onClick={handleClick}
    >
      { label }
    </ButtonElement>
  );
};

export default Button;
