import React, { useEffect, useState } from 'react';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { options } from './InschrijvenVoorlichting';
import Navigation from '../components/Navigation';
import Footer from '../components/Footer';
import {
  ContentContainer,
  GlobalContainer,
  NavigationContainer,
} from '../components/Container';
import {
  TwoColumnGrid,
  Column,
} from '../layouts/TwoColumnGrid';
import ContactForm from '../components/forms/ContactForm';
import { client } from '../client';

const ContactPage = () => {
  const [content, setContent] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      const result = await client.getEntries({
        content_type: 'contactPage',
        include: 10,
      });
      setContent(result.items[0].fields);
    };
    fetchData();
  }, []);

  return (
    <>
      {content
        && (
          <>
            <GlobalContainer>
              <NavigationContainer>
                <Navigation inverted />
              </NavigationContainer>
            </GlobalContainer>
            <GlobalContainer>
              <ContentContainer>
                <TwoColumnGrid>
                  <Column>
                    {documentToReactComponents(content.beschrijving, options)}
                  </Column>
                  <Column>
                    <ContactForm
                      firstnamePlaceholder="Voornaam"
                      surnamePlaceholder="Achternaam"
                      emailPlaceholder="Email adres"
                      phonePlaceholder="Telefoonnummer"
                      remarksPlaceholder={content.formulierWaardes.fields.opmerkingenPlaceholder}
                      errorMessage={content.formulierWaardes.fields.foutmelding}
                      succesMessage={content.formulierWaardes.fields.succesmelding}
                      disclaimer={content.formulierWaardes.fields.disclaimer}
                      buttonLabel={content.formulierWaardes.fields.buttonTekst}
                    />
                  </Column>
                </TwoColumnGrid>
              </ContentContainer>
            </GlobalContainer>
            <Footer />
          </>
        )}
    </>
  );
};

export default ContactPage;
